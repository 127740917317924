<template>
    <div id="Dashboard" class="bg-success p-0 m-0">

    </div>
</template>

<script>
  
    export default {
        data() {
            return {
                
            }
        }
    }

</script>

<style lang="scss" scoped>
    #Dashboard {
        
    }
</style>
